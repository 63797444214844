<template>
  <div
    v-if="
      getUser.role.slug === 'rh' ||
        getUser.role.slug === 'super-administrateur'
    "
    no-body
  >
    <b-card>
      <b-card-header class="pb-50">
        <h5>Filtrages</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="10"
            lg="10"
            class="mb-md-0 mb-2"
          >
            <b-row>
              <b-col
                cols="12"
                sm="6"
                md="6"
                lg="6"
              >
                <label for="">Service</label>
                <v-select
                  v-model="payloadFilter.service_id"
                  :options="recurringServicesOption"
                  class="invoice-filter-select"
                  label="name"
                  :reduce="(service) => service.id"
                >
                  <template v-slot:no-options>
                    <b-spinner
                      v-if="isServicesRecurringWithoutPaginationLoading"
                      style="width: 2.5rem; height: 2.5rem"
                      class="align-middle text-info"
                    />
                    <span
                      v-else
                      class="mt-1 font-medium-1"
                    >Aucun service ne correspond</span>
                  </template>
                </v-select>
              </b-col>

              <b-col
                cols="12"
                sm="6"
                md="2"
                lg="2"
                class="mt-2"
              >
                <b-button
                  variant="primary"
                  @click="applyFilterRecurringServicesAction()"
                >
                  <div v-if="isReLoadFilterDataEmployees">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>

                  <span
                    v-else
                    class="text-nowrap font-medium-1"
                  >Filtrer</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              v-b-modal.recurring-service-modal
              variant="primary"
            >
              <span class="text-nowrap">Ajouter</span>
            </b-button>
            <b-modal
              id="recurring-service-modal"
              cancel-variant="outline-secondary"
              hide-footer
              centered
              no-close-on-backdrop
              no-close-on-esc
              title="Ajout d'un service récurrent"
            >
              <validation-observer
                #default="{}"
                ref="addRecurringServiceForm"
              >
                <!-- Form -->
                <b-form
                  class="p-2"
                  autocomplete="off"
                  @reset.prevent="hideModal"
                  @submit.prevent="applyAddRecurringServiceAction"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="designation"
                    rules="required"
                  >
                    <b-form-group
                      label="Désignation"
                      label-for="designation"
                    >
                      <b-form-input
                        id="designation"
                        v-model="payload.name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    #default="{ errors }"
                    name="placement_fee"
                    rules="required"
                  >
                    <b-form-group
                      label="Frais de placement"
                      label-for="placement_fee"
                    >
                      <b-form-input
                        id="percentage"
                        v-model="payload.placement_fee"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                        placeholder=""
                        rows="3"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <b-form-group
                    label="Image du service"
                    label-for="name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="image"
                      rules="required"
                    >
                      <b-form-file
                        id="wildcard"
                        v-model="payload.image"
                        accept="image/*"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Form Actions -->
                  <div class="d-flex mt-2">
                    <b-button
                      variant="primary"
                      class="mr-2"
                      type="submit"
                    >
                      <div v-if="isAddRecurringServiceLoading">
                        <span> Chargement ... </span>
                        <b-spinner small />
                      </div>
                      <span v-else>Enregistrer</span>
                    </b-button>
                    <b-button
                      type="reset"
                      variant="outline-secondary"
                    >
                      Annuler
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-modal>
            <b-modal
              id="update-recurring-service-modal"
              cancel-variant="outline-secondary"
              hide-footer
              centered
              no-close-on-backdrop
              no-close-on-esc
              title="Modification d'un service récurrent"
            >
              <validation-observer
                #default="{}"
                ref="updateServiceForm"
              >
                <!-- Form -->
                <b-form
                  class="p-2"
                  autocomplete="off"
                  @reset.prevent="hideUpdateServiceModal"
                  @submit.prevent="applyUpdateRecurringServiceAction"
                >
                  <b-media class="mb-2">
                    <template #aside>
                      <b-avatar
                        ref="previewEl"
                        :src="serviceImage"
                        size="100px"
                        rounded
                      />
                    </template>

                    <div class="d-flex flex-wrap">
                      <b-button
                        variant="primary"
                        @click="$refs.refInputEl.$el.click()"
                      >
                        <b-form-file
                          ref="refInputEl"
                          v-model="payload.image"
                          accept=".jpg, .png, .gif, .jpeg"
                          :hidden="true"
                          plain
                          @change="displayImage"
                        />
                        <span>Changer l'image</span>
                      </b-button>
                    </div>
                  </b-media>
                  <validation-provider
                    #default="{ errors }"
                    name="placement_fee"
                    rules="required"
                  >
                    <b-form-group
                      label="Frais de placement"
                      label-for="placement_fee"
                    >
                      <b-form-input
                        id="placement_fee"
                        v-model="payload.placement_fee"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                        placeholder=""
                        rows="3"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    #default="{ errors }"
                    name="designation"
                    rules="required"
                  >
                    <b-form-group
                      label="Désignation"
                      label-for="designation"
                    >
                      <b-form-input
                        id="designation"
                        v-model="payload.name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <!-- Form Actions -->
                  <div class="d-flex mt-2">
                    <b-button
                      variant="primary"
                      class="mr-2"
                      type="submit"
                    >
                      <div v-if="isUpdateRecurringServiceLoading">
                        <span> Chargement ... </span>
                        <b-spinner small />
                      </div>
                      <span v-else>Modifier</span>
                    </b-button>
                    <b-button
                      type="reset"
                      variant="outline-secondary"
                    >
                      Annuler
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-modal>

          
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <b-button
              variant="primary"
              @click="reLoadDataRecurringServicesAction()"
            >
              <div v-if="isReLoadDataRecurringServices">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span
                v-else
                class="text-nowrap font-medium-1"
              >Actualiser</span>
            </b-button>
          </b-col>
        </b-row>
      </div>

      <vue-good-table
        class="m-2"
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :is-loading="isRecurringServicesLoading"
      >
        <template slot="emptystate">
          <div class="text-center">
            <span> {{ isRecurringServicesLoading ? "" : "Liste vide" }} </span>
          </div>
        </template>
        <template slot="loadingContent">
          <div class="text-center text-info">
            <b-spinner class="align-middle" /> &nbsp;
          </div>
        </template>

        <template
          slot="table-row"
          slot-scope="props"
        >
          <div
            v-if="props.column.field == 'image'"
            class="align-middle text-center"
          >
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  rounded
                  size="64"
                  :src="props.row.image"
                >
                  <feather-icon
                    v-if="props.row.image == ''"
                    size="24"
                    icon="ShoppingBagIcon"
                  />
                </b-avatar>
              </template>
            </b-media>
          </div>

          <div
            v-if="props.column.field == 'name'"
            class="align-middle text-center"
          >
            <span class="text-info font-small-5">
              {{ props.row.name }}
            </span>
          </div>
          <div
            v-if="props.column.field == 'placement_fee'"
            class="align-middle text-center"
          >
            <span
              class="font-small-5"
              :class="[props.row.placement_fee > 0 ? 'text-info' : 'text-danger']"
            >
              {{ props.row.placement_fee }}
            </span>
          </div>
          <div
            v-if="props.column.field == 'employee_count'"
            class="align-middle text-center"
          >
            <span
              class="font-small-5"
              :class="[
                props.row.employees_count > 0 ? 'text-info' : 'text-danger',
              ]"
            >
              {{ props.row.employees_count }}
            </span>
          </div>
          <div
            v-if="props.column.field == 'action'"
            class="align-middle text-center"
          >
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="25"
                    class="text-info align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  class="itemActions cursor-pointer"
                  @click="showUpdateRecurringServiceModal(props.row)"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="text-info mr-1"
                    size="25"
                  />
                  <span>Modifier</span>
                </b-dropdown-item>
                <b-dropdown-item
                  class="itemActions cursor-pointer"
                  @click="quizRecurringService(props.row)"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    class="text-info mr-1"
                    size="25"
                  />
                  <span>Créer un questionnaire</span>
                </b-dropdown-item>
                <b-dropdown-item
                v-b-modal.quiz-recurring-service-modal
                  class="itemActions cursor-pointer"
                  @click="applygetQuiz(props.row)"
                >
                  <feather-icon
                    icon="EyeIcon"
                    class="text-success mr-1"
                    size="25"
                  />
                  <span>Voir le questionnaire</span>
                </b-dropdown-item>
                <b-dropdown-item
                  class="itemActions cursor-pointer"
                  @click="applyDeleteRecurringServiceAction(props.row.id)"
                >
                  <feather-icon
                    class="text-danger mr-1"
                    size="25"
                    icon="TrashIcon"
                  />
                  <span>Supprimer</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </div>
        </template>
      </vue-good-table>

      <div
        class="d-flex justify-content-between m-2 flex-wrap"
      >
        <b-button
          :disabled="metaData.prev_page_url == null"
          variant="outline-secondary"
          @click="applyLoadMoreRecurringServicesAction(metaData.prev_page_url)"
        >
          <span
            class="text-white text-nowrap font-medium-2"
          >Page précédente</span>
        </b-button>

        <div>
          <span
            class="text-white font-medium-2"
          >{{ metaData.current_page }} sur {{ metaData.last_page }} pages</span>
        </div>

        <div >
          <span
            class="text-white font-medium-2"
          >Total :{{total }}</span>
        </div>

        <b-button
          :disabled="metaData.next_page_url == null"
          variant="outline-secondary"
          @click="applyLoadMoreRecurringServicesAction(metaData.next_page_url)"
        >
          <span class="text-nowrap font-medium-1 text-white">Page suivante</span>
        </b-button>
      </div>
    </b-card>
      <!-- List des Questions -->
      <b-modal
          id="quiz-recurring-service-modal"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          no-close-on-backdrop
          no-close-on-esc
          title="Questions ajoutés"
          size="xl"
          stacking
        >
          <b-table
            ref="refInvoiceListTable"
            :items="quiz"
            responsive
            :fields="columnsQuiz"
            primary-key="id"
            :busy="isQuizLoading"
            show-empty
            empty-text="Liste vide"
            class="position-relative"
          >
            <template #table-busy>
              <div class="text-center text-info my-2">
                <b-spinner class="align-middle" /> &nbsp;
                <strong>Chargement...</strong>
              </div>
            </template>
            <!-- Commentaire -->
            <template #cell(question)="data">
              <span class="text-info"> {{ data.item.question }} </span>
            </template>
            <!-- Make by -->
            <template #cell(add_by)="data">
              <span class="text-info">
                {{ data.item.add_by.first_name + ' ' + data.item.add_by.last_name}}
              </span>
            </template>
             <!-- Make at -->
            <template #cell(created_at)="data">
              <span class="text-info">
                {{ moment(data.item.created_at).locale("fr").format("llll")}}
              </span>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <div class="text-nowrap">
                <b-dropdown variant="link" toggle-class="p-0" no-caret>
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="25"
                      class="align-middle text-center text-info"
                    />
                  </template>
                 
                  <b-dropdown-item
                  v-b-modal.modal-update-quiz
                    :id="`update${data.item.id}`"
                    class="cursor-pointer itemActions"
                    @click="applyUpdateQuizAction(data.item)"
                  >
                    <feather-icon icon="EditIcon" class="text-success" size="20" />
                    <span class="align-middle ml-50">Modifier question</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    :id="`delete${data.item.id}`"
                    class="cursor-pointer itemActions"
                    @click="applyDeleteQuiz(data.item)"
                  >
                    <feather-icon icon="TrashIcon" class="text-danger" size="20" />
                    <span class="align-middle ml-50">Supprimer</span>
                  </b-dropdown-item>
                  
                </b-dropdown>
              </div>
              </template>
          </b-table>
        </b-modal>

        <!-- Update Quiz Modal -->
        <b-modal
        id="modal-update-quiz"
        cancel-variant="outline-secondary"
        hide-footer
        centered
        no-close-on-backdrop
        no-close-on-esc
        title="Modifier la question"
      >
        <validation-observer
          #default="{}"
          ref="UpdateQuiz"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            autocomplete="off"
            @reset.prevent="hideModalUpdateQuiz()"
            @submit.prevent="applyUpdateQuiz()"
          >
              <validation-provider
                #default="{ errors }"
                name="question"
                rules="required"
              >
                <b-form-group
                  label="Question"
                  label-for="question"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-textarea
                    id="question"
                    v-model="updateQuizPayload.question"
                    rows="3"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                :disabled="isUpdateQuiz"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                <div v-if="isUpdateQuiz">
                  <span> Chargement ... </span>
                  <b-spinner small />
                </div>
                <span v-else>Modifier la question</span>
              </b-button>
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                Annuler
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>

  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  // BTab,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BFormTextarea,
  // BLink,
  // BFormSelect,
  // BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  // BTooltip,
  BFormGroup,
  BForm,
  BSpinner,
  BFormFile,
  // BTabs,
  // BAlert,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'

export default {
  components: {
    // BTab,
    BFormTextarea,
    BFormFile,
    // BFormSelect,
    ValidationProvider,
    ValidationObserver,
    // BTabs,
    BCard,
    // BAlert,
    BFormGroup,
    // BLink,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    // BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BForm,
    // BTooltip,
    BSpinner,
    vSelect,
    VueGoodTable,
  },
  data() {
    return {
      quizId: "",
      updateQuizPayload: {
        question : '',
        service_id: ''
      },
      isUpdateQuiz: false,
      isQuizLoading: false,
      quiz: [],
       columnsQuiz: [
        {
          key: "question",
          label: "Questions",
          sortable: true,
          class: "font-medium-1",
        },
        {
          key: "add_by",
          label: "Question ajouté par",
          sortable: true,
          class: "font-medium-1",
        },
        {
          key: "created_at",
          label: "Date d'ajout",
          sortable: true,
          class: "font-medium-1",
        },
        
        
        { key: "actions", class: "font-medium-1" },
      ],
      total:'',
      payloadFilter: {
        service_id: '',
      },

      recurringServicesOption: [],

      isReLoadFilterData: false,

      tableItems: [],
      required,
      alphaNum,
      isAddRecurringServiceLoading: false,
      isUpdateRecurringServiceLoading: false,
      isReLoadDataRecurringServices: false,
      serviceId: null,
      serviceImage: null,
      payload: {
        name: '',
        placement_fee: null,
       
      },
      email,

      rows: [],
      columns: [
        {
          label: 'Image',
          field: 'image',
        },
        {
          label: 'Nom',
          field: 'name',
          // filterOptions: {
          //   enabled: true,
          //   placeholder: 'Rechercher...',
          //   filterFn(data, filterString) {
          //     return data.toLowerCase().includes(filterString.toLowerCase())
          //   },
          // },
        },
        {
          label: 'Frais de placement',
          field: 'placement_fee',

        },
        {
          label: "Nombre d'employés",
          field: 'employee_count',

        },
        {
          label: 'Actions',
          field: 'action',
        },
      ],
      dir: false,
      statusFilter: null,
      avatarText,
    }
  },

  computed: {
    ...mapGetters('services', ['getRecurringServices', 'getRecurringServicesWithoutPagination']),
    ...mapGetters(['isServicesRecurringWithoutPaginationLoading']),

    ...mapGetters(['isRecurringServicesLoading']),
    ...mapGetters('auth', ['getUser']),

    metaData() {
      const meta = {
        prev_page_url: '',
        next_page_url: '',
        current_page: '',
        last_page: '',
        current_page_url: '',
      }
      if (this.getRecurringServices != null) {
        meta.prev_page_url = this.getRecurringServices.prev_page_url
        meta.next_page_url = this.getRecurringServices.next_page_url
        meta.current_page = this.getRecurringServices.current_page
        meta.last_page = this.getRecurringServices.last_page
        meta.current_page_url = `${this.getRecurringServices.path}?page=${this.getRecurringServices.current_page}`
        this.total = this.getRecurringServices.total
      }
      return meta
    },

    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    },
  },

  watch: {
    payloadFilter: {
      handler(newValue, oldValue) {
        const keys = Object.keys(newValue)
        keys.forEach(key => {
          if (newValue[key] == null) {
            newValue[key] = ''
            console.log(this.payloadFilter)
          }
        })
      },
      deep: true,
    },

    isRecurringServicesLoading(val) {
      if (val === false) {
        this.rows = this.getRecurringServices.data
      }
    },

    isServicesRecurringWithoutPaginationLoading(val) {
      if (val === false) {
        this.getRecurringServicesWithoutPagination.forEach(element => {
          this.recurringServicesOption.push({
            id: element.id,
            name: element.name,
          })
        })
      }
    },
  },

  created() {
    this.getRecurringServices.data != null
      ? (this.rows = this.getRecurringServices.data)
      : null
    if (this.isRecurringServicesLoading === false) {
      this.rows = this.getRecurringServices.data
    }
  },

  mounted() {
    if (this.getRecurringServicesWithoutPagination.length != 0) {
      this.getRecurringServicesWithoutPagination.forEach(element => {
        this.recurringServicesOption.push({
          id: element.id,
          name: element.name,
        })
      })
    }
  },

  methods: {
    ...mapActions('services', [
      'addRecurringServiceAction',
      'deleteRecurringServiceAction',
      'getRecurringServicesAction',
      'updateRecurringServiceAction',
      'loadMoreRecurringServicesAction',
      'filterRecurringServicesAction',
      'getQuizAction',
      'deleteQuizAction',
      'updateQuizAction'
    ]),

    applygetQuiz(service){
      this.serviceQuiz = service
      this.isQuizLoading = true
        this.getQuizAction(service.id)
        .then(response =>{
          this.isQuizLoading = false
          this.quiz = response.data
          console.log(response.data)
          
        })
        .catch(error => {
          this.isQuizLoading = false
          console.log(error)
        })
      },
    quizRecurringService(service){
      this.$router.push(`/recurring/services/${service.id}/quiz`)
    },
    applyDeleteQuiz(quiz) {
      this.$swal({
        title: "Suppression",
        text: "Êtes-vous sûr de vouloir supprimer cette question ?",
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: "Non",
        allowOutsideClick: false,
        confirmButtonText: "Oui",
        customClass: {
          confirmButton: "btn btn-warning",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.deleteQuizAction(quiz.id);
          return await this.applygetQuiz(this.serviceQuiz);
        },
      })
        .then((response) => {
          console.log(response);
          if (response.isConfirmed) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Succès",
                  icon: "CheckIcon",
                  text: "Question supprimée avec succès",
                  variant: "success",
                },
              },
              {
                position: "top-center",
              }
            );
          }
          this.$bvModal.show("modal-comments");
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Erreur",
                icon: "AlertTriangleIcon",
                text: error.message.data.message,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
        });
    },
    applyUpdateQuizAction(item){
      this.quizId = item.id,
      this.updateQuizPayload = {
        question: item.question,
       
        service_id: item.recurring_service_id
       
      }
    },
     applyUpdateQuiz() {
      this.$refs.UpdateQuiz.validate().then(success => {
        if (success) {
          this.isUpdateQuiz = true
          this.updateQuizAction({
            id:  this.quizId ,
            payload: this.updateQuizPayload
          })
            .then(response => {
              this.isUpdateQuiz = false
              this.applygetQuiz(this.serviceQuiz)
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.hideModalUpdateQuiz()
            })
            .catch(error => {
              console.log(error)
              this.isUpdateQuiz = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'AlertTriangleIcon',
                    text: response.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            })
        }
      })
    },
    hideModalUpdateQuiz() {
      this.$bvModal.hide("modal-update-quiz");
    },

    applyFilterRecurringServicesAction() {
      const allIsNUl = Object.values(this.payloadFilter).every(
        el => el === '',
      )
      if (allIsNUl) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Warning',
              icon: 'CheckIcon',
              text: 'Veuillez sélectionner au moins un champ du filtre.',
              variant: 'warning',
            },
          },
          {
            position: 'top-center',
          },
        )
      } else {
        this.isReLoadFilterData = true
        this.rows = []
        this.filterRecurringServicesAction(this.payloadFilter)
          .then(response => {
            this.isReLoadFilterData = false
            this.rows = this.getRecurringServices
            this.total = this.rows.length
          })
          .catch(error => {
            console.log(error)
            this.isReLoadFilterData = false
          })
      }
    },

    displayImage() {
      // eslint-disable-next-line prefer-destructuring
      this.payload.image = this.$refs.refInputEl.$el.files[0]
      this.serviceImage = URL.createObjectURL(this.payload.image)
    },

    applyLoadMoreRecurringServicesAction(url) {
      this.$store.commit('SET_IS_RECURRING_SERVICES_LOADING', true)
      this.rows = []
      this.loadMoreRecurringServicesAction(url)
        .then(() => {
          this.$store.commit('SET_IS_RECURRING_SERVICES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_RECURRING_SERVICES_LOADING', false)
        })
    },

    reLoadDataRecurringServicesAction() {
      this.payloadFilter.service_id = ''
      this.isReLoadDataRecurringServices = true
      this.$store.commit('SET_IS_RECURRING_SERVICES_LOADING', true)
      this.rows = []
      this.getRecurringServicesAction()
        .then(response => {
          this.isReLoadDataRecurringServices = false
          this.$store.commit('SET_IS_RECURRING_SERVICES_LOADING', false)
        })
        .catch(error => {
          this.isReLoadDataRecurringServices = false
          this.$store.commit('SET_IS_RECURRING_SERVICES_LOADING', false)
        })
    },

    showUpdateRecurringServiceModal(service) {
      this.$bvModal.show('update-recurring-service-modal')
      this.serviceId = service.id
      this.serviceImage = service.image
      this.payload.name = service.name
      this.payload.placement_fee = service.placement_fee
    },

    showQuizRecurringServiceModal(service) {
      this.serviceId = service.id
      console.log( this.serviceId)
      this.serviceImage = service.image
      this.payload.name = service.name
      this.payload.placement_fee = service.placement_fee
    },

    applyAddRecurringServiceAction() {
      this.$refs.addRecurringServiceForm.validate().then(success => {
        if (success) {
          // eslint-disable-next-line no-unused-expressions
          this.isAddRecurringServiceLoading = true
          this.addRecurringServiceAction(this.payload)
            .then(response => {
              this.getRecurringServicesAction().then(() => {
                this.isAddRecurringServiceLoading = false

                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Succès',
                      icon: 'CheckIcon',
                      text: response.message,
                      variant: 'success',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )

                this.$bvModal.hide('recurring-service-modal')
                this.payload = {
                  name: '',
                }
              })
            })
            .catch(error => {
              console.log(error)
              this.isAddRecurringServiceLoading = false
            })
        }
      })
    },

    applyUpdateRecurringServiceAction() {
      this.$refs.updateServiceForm.validate().then(success => {
        if (success) {
          this.isUpdateRecurringServiceLoading = true
          if (this.payload.image == null) {
            delete this.payload.image
          }
          this.updateRecurringServiceAction({
            payload: this.payload,
            id: this.serviceId,
          })
            .then(response => {
              this.getRecurringServicesAction().then(() => {
                this.isUpdateRecurringServiceLoading = false
                this.applyLoadMoreRecurringServicesAction(this.metaData.current_page_url)
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Succès',
                      icon: 'CheckIcon',
                      text: response.message,
                      variant: 'success',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )

                this.$bvModal.hide('update-recurring-service-modal')
                this.payload = {
                  name: '',
                  placement_fee: null,
                  image: '',
                }
              })
            })
            .catch(error => {
              console.log(error)
              this.isUpdateRecurringServiceLoading = false
            })
        }
      })
    },

    applyDeleteRecurringServiceAction(id) {
      this.$swal({
        title: 'Suppression',
        text: 'Êtes-vous sûr de vouloir supprimer ce service récurrent ?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.deleteRecurringServiceAction(id)
          // eslint-disable-next-line no-return-await
          return await this.getRecurringServicesAction()
        },
      })
        .then(response => {
          if (response.value) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Service supprimé avec succès',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    hideModal() {
      this.$bvModal.hide('recurring-service-modal')
      this.payload = {
        name: '',
        placement_fee: null,
        image: '',
      }
    },

    hideUpdateServiceModal() {
      this.$bvModal.hide('update-recurring-service-modal')
      this.payload = {
        name: '',
        placement_fee: null,
        image: '',
      }
    },
  },
}
</script>

<style>
i.icon-code {
  display: none !important;
}

.vgt-select {
  background: transparent !important;
  border-color: #404656 !important;
}

.itemActions :hover {
  background: transparent !important;
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
